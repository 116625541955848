import React from 'react'
import 'isomorphic-fetch'
import { SessionProvider } from './src/services/session'
import { TravelPageProvider } from './src/screens/travel-page/Provider'
import { ApolloProvider } from '@apollo/client'
import { getSubscriptionClient} from './src/services/apollo'
import './src/styles/global.css'
import 'moment/locale/es'
import moment from 'moment'

moment.locale('es')

export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={getSubscriptionClient()}>
        <SessionProvider>
          <TravelPageProvider>{element}</TravelPageProvider>
        </SessionProvider>
    </ApolloProvider>
)



