import jsonwebtoken from 'jsonwebtoken'

import { TokenUser } from '../types/user.type'

interface tokenInfoResult {
  token: string
  user: TokenUser
}

export const isBrowser = (): boolean => typeof window !== 'undefined'

// TODO: Deberia llamar al back para confirmar el user atraves del token
export const getUser = (): string | null => {
  if (isBrowser() && window.localStorage.getItem('token'))
    return window.localStorage.getItem('token')

  return null
}

export const getCurrentUser = (): TokenUser | null => {
  const token = isBrowser() ? window.localStorage.getItem('token') : null;
  if (!token) return null;

  const user = jsonwebtoken.decode(token) as TokenUser;
  return user;
};

// TODO: Check functionality.
// const setUser = (token: string): void =>
//   window.localStorage.setItem('token', token)
export const isLoggedIn = (): boolean => {
  const user = getUser()
  return !!user
}

export const logout: () => void = async () => {
  window.localStorage.removeItem('token')
  window.location.replace('/inicio-sesion/')
  return null
}

export const tokenInfo = (token: string): tokenInfoResult => {
  if (!token) logout()
  const user = jsonwebtoken.decode(token) as TokenUser
  localStorage.setItem('token', token)
  return { token, user }
}
export const guestEmail = 'invitado'
export const publicTokenInfo = (token: string): tokenInfoResult => {
  const invalidData = 'guest'
  if (!token)
    return {
      user: {
        company: invalidData,
        email: guestEmail,
        role: invalidData,
        status: invalidData,
        sub: invalidData,
      },
      token: 'invalid',
    }
  const user = jsonwebtoken.decode(token) as TokenUser
  localStorage.setItem('token', token)
  return { token, user }
}

export const cleanToken = (): void => window.localStorage.removeItem('token')
