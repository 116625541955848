export enum RolesEnum {
  CCC = 'CCC',
  SCC = 'SCC',
  COMMERCIAL_EXECUTIVE = 'COMMERCIAL_EXECUTIVE',
  COMMERCIAL_ADMIN = 'COMMERCIAL_ADMIN',
  CUSTOMER_SUPPORT = 'CUSTOMER_SUPPORT',
  ADMIN_CCC = 'ADMIN_CCC',
  ADMIN_SCC = 'ADMIN_SCC',
  FINANCE_ADMIN = 'FINANCE_ADMIN',
  DISCOUNT_ADMIN = 'DISCOUNT_ADMIN',
  MULTI_RUT_ADMIN = 'MULTI_RUT_ADMIN',
  MULTI_RUT_BUYER = 'MULTI_RUT_BUYER',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export enum ErrorMessageEnum {
  UNAUTHORIZED = 'Unauthorized',
}

export enum userStatus {
  Active = 'ACTIVE',
  Blocked = 'BLOCKED',
  Inactive = 'INACTIVE',
  Pending = 'PENDING',
}

export enum DiscountTypeEnum {
  PERCENT_DISCOUNT = 'PERCENT_DISCOUNT',
  FLAT_RATE_DISCOUNT = 'FLAT_RATE_DISCOUNT',
}

export enum RowJustify {
  center = 'center',
  spaceBetween = 'space-between',
}

export enum BuyStatus {
  PAYMENT_PENDING = 'PAYMENT_PENDING',
  VALID = 'VALID',
  CANCELED = 'CANCELED',
}

export enum HelpRequestStatusEnum {
  NOT_INITED = 'NOT_INITED',
  IN_PROGRESS = 'IN_PROGRESS',
  FINISHED = 'FINISHED',
}

export enum InvoiceStatus {
  IN_PROGRESS = 'inProgress',
  UNPAID = 'unpaid',
  PAID = 'paid',
  EXPIRED = 'expired',
  NULLED = 'nulled',
}

export const displayHelpRequestStatus = (
  statusEnum: HelpRequestStatusEnum,
): string => {
  switch (statusEnum) {
    case HelpRequestStatusEnum.NOT_INITED:
      return 'ENVIADO'
    case HelpRequestStatusEnum.IN_PROGRESS:
      return 'EN CURSO'
    case HelpRequestStatusEnum.FINISHED:
      return 'RESUELTA'
    default:
      return ''
  }
}
