// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-inicio-sesion-forget-password-form-index-tsx": () => import("./../../../src/pages/inicio-sesion/forgetPasswordForm/index.tsx" /* webpackChunkName: "component---src-pages-inicio-sesion-forget-password-form-index-tsx" */),
  "component---src-pages-inicio-sesion-index-tsx": () => import("./../../../src/pages/inicio-sesion/index.tsx" /* webpackChunkName: "component---src-pages-inicio-sesion-index-tsx" */),
  "component---src-pages-inicio-sesion-log-index-tsx": () => import("./../../../src/pages/inicio-sesion/log/index.tsx" /* webpackChunkName: "component---src-pages-inicio-sesion-log-index-tsx" */),
  "component---src-pages-inicio-tsx": () => import("./../../../src/pages/inicio.tsx" /* webpackChunkName: "component---src-pages-inicio-tsx" */),
  "component---src-pages-recuperar-cuenta-index-tsx": () => import("./../../../src/pages/recuperar-cuenta/index.tsx" /* webpackChunkName: "component---src-pages-recuperar-cuenta-index-tsx" */),
  "component---src-screens-help-page-index-tsx": () => import("./../../../src/screens/help-page/index.tsx" /* webpackChunkName: "component---src-screens-help-page-index-tsx" */)
}

