import { RolesEnum } from './enums'

const getToken = (): string => window?.localStorage?.getItem('token') ?? ''

const platformRoles: string[] = [
  RolesEnum.COMMERCIAL_ADMIN,
  RolesEnum.COMMERCIAL_EXECUTIVE,
  RolesEnum.CUSTOMER_SUPPORT,
  RolesEnum.FINANCE_ADMIN,
  RolesEnum.DISCOUNT_ADMIN,
  RolesEnum.SUPER_ADMIN,
]

const isPlatformUser = (role: string): boolean => platformRoles.includes(role)

const isCCC = (role: RolesEnum): boolean =>
  [
    RolesEnum.ADMIN_CCC,
    RolesEnum.CCC,
    RolesEnum.MULTI_RUT_BUYER,
    RolesEnum.MULTI_RUT_ADMIN,
  ].includes(role)

export { getToken, isCCC, isPlatformUser }
