import React, { createContext, FC, useState } from 'react'
import { TokenCompany } from '../types/company.type'

import { TokenUser } from '../types/user.type'

type SessionContextType = {
  token: string
  user: TokenUser
  company: TokenCompany
  setToken: (token: string) => void
  setUser: (user: TokenUser) => void
  setCompany: (company: TokenCompany) => void

}

// Eslint ignore in both cases for convinience.

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const SessionContext = createContext<SessionContextType>(undefined!)

export const SessionProvider: FC = ({ children }) => {
  const [token, setToken] = useState('')
  const [user, setUser] = useState({} as TokenUser)
  const [company, setCompany] = useState({} as TokenCompany)

  return (
    <SessionContext.Provider value={{ token, user, company, setToken, setUser, setCompany }}>
      {children}
    </SessionContext.Provider>
  )
}
