import {
  ApolloClient,
  ApolloLink,
  concat,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { MultiAPILink } from '@habx/apollo-multi-endpoint-link'
import { navigate } from 'gatsby'
import { SubscriptionClient } from 'subscriptions-transport-ws'
import WebSocket from 'isomorphic-ws'; // Importa isomorphic-ws

import { getToken } from '../helpers/auth.helper'
import { ErrorMessageEnum } from '../helpers/enums'
import { cleanToken } from './auth'

// Define el endpoint de tu API GraphQL
const apiUri =
  typeof window !== 'undefined' && window?.location?.href.includes('localhost')
    ? process.env.GATSBY_LOCAL_API_URI
    : process.env.GATSBY_API_URI

// Define el endpoint de tu API WebSocket
const wsUri = process.env.GATSBY_LOCAL_API_USUARIO_WS || `ws://localhost:3100/graphql`;

// Función para manejar errores en las consultas
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.map(({ message }) => {
      if (message === ErrorMessageEnum.UNAUTHORIZED) {
        cleanToken()
        // Redirecciona al usuario a la página de inicio de sesión si hay un error de autenticación
        // Cambia '/inicio-sesion/' según tu configuración
        navigate('/inicio-sesion/')
      }
      console.log(`[GraphQL error]: ${message}`)
      return undefined
    })

  if (networkError) console.log(`[Network error]: ${networkError}`)
})

// Configura el contexto de autenticación para agregar el token de autenticación a las solicitudes
const authLink = setContext((_, { headers }) => {
  const token = getToken()

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  }
})

// Función para enrutar solo las suscripciones a GATSBY_LOCAL_API_USUARIO
const splitLink = ApolloLink.from([new WebSocketLink({
  uri: wsUri,
  options: {
    reconnect: true,
    connectionParams: () => ({
      authToken: getToken()
    })
  },
  webSocketImpl: WebSocket
})])

// Enlace HTTP para consultas y mutaciones usando MultiAPILink
const httpLink = ApolloLink.from([
  new MultiAPILink({
    endpoints: {
      users: process.env.GATSBY_LOCAL_API_USUARIO as string,
      ventas: process.env.GATSBY_LOCAL_API_URI as string,
      reports: process.env.GATSBY_LOCAL_API_REPORTES as string,
    },
    createHttpLink: () => createHttpLink(),
  }),
])

// Combinar los enlaces de autenticación, manejo de errores y HTTP para consultas y mutaciones
const combinedLink = concat(
  authLink,
  concat(
    errorLink,
    split(
      ({ query }) => {
        const definition = getMainDefinition(query)
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        )
      },
      splitLink,
      httpLink
    )
  )
);

// Exporta una función para obtener el cliente de Apollo
export const getSubscriptionClient = (): ApolloClient<NormalizedCacheObject> => {
  return new ApolloClient({
    link: combinedLink,
    cache: new InMemoryCache(),
  })
}
