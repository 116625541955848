import React, { createContext, FC, useState } from 'react'

import { QueryString, Quota, Transaction, TravelOptions } from '../types'
import { useEffect } from 'react';

type TravelPageContextType = {
  anonymous: boolean
  drawerRender: boolean
  hasReturn: boolean
  integrationTokens: string[]
  isGone: boolean
  options: TravelOptions
  search: QueryString
  step: number
  transaction: Transaction
  quotas: Quota[]
  currentQuotaSelected?: Quota | null
  visible: boolean
  passengersView: boolean
  activeIsGoneTravel?: boolean
  percentDiscountValue?: number
  uuidTransbank?: string
  updateUuidTransbank: (uuid: string) => void;
  setPercentDiscountValue: (value: number) => void
  setActiveIsGoneTravel: (value: boolean | undefined) => void
  setCurrentQuotaSelected: (value: Quota | null) => void
  setQuotas: (value: Quota[]) => void
  setPassengersView: (value: boolean) => void
  setAnonymous: (value: boolean) => void
  setDrawerRender: (value: boolean) => void
  setHasReturn: (value: boolean) => void
  setIntegrationTokens: (tokens: string[]) => void
  setIsGone: (value: boolean) => void
  setOptions: (options: TravelOptions) => void
  setSearch: (search: QueryString) => void
  setStep: (step: number) => void
  setTransaction: (transaction: Transaction) => void
  setVisible: (value: boolean) => void
  setuuidTransbank: (value: string) => void
}

export const TravelPageContext = createContext<TravelPageContextType>(
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  undefined!,
)

export const TravelPageProvider: FC = ({ children }) => {
  const [anonymous, setAnonymous] = useState(false)
  const [drawerRender, setDrawerRender] = useState(false)
  const [hasReturn, setHasReturn] = useState(false)
  const [isGone, setIsGone] = useState(true)
  const [options, setOptions] = useState({
    destinies: [],
    origins: [],
    routes: [],
  } as TravelOptions)
  const [search, setSearch] = useState({ numberOfSeats: '1' } as QueryString)
  const [step, setStep] = useState(0)
  const [transaction, setTransaction] = useState({} as Transaction)
  const [visible, setVisible] = useState(false)
  const [integrationTokens, setIntegrationTokens] = useState<string[]>([])
  const [passengersView, setPassengersView] = useState<boolean>(false)
  const [quotas, setQuotas] = useState<Quota[]>([])
  const [activeIsGoneTravel, setActiveIsGoneTravel] = useState<
    boolean | undefined
  >(undefined)
  const [
    currentQuotaSelected,
    setCurrentQuotaSelected,
  ] = useState<Quota | null>(null)
  const [percentDiscountValue, setPercentDiscountValue] = useState<number | undefined>(undefined)
  /*nuevo*/
  const [uuidTransbank, setuuidTransbank] = useState('')
  const updateUuidTransbank = (uuid: string) => {
    console.log("uuid recibida", uuid)
    setuuidTransbank(uuid);
    localStorage.setItem('uuidTransbank', uuid);
  };

  useEffect(() => {
    if (!uuidTransbank) {
      const storedUuidTransbank = localStorage.getItem('uuidTransbank');
      if (storedUuidTransbank) {
        setuuidTransbank(storedUuidTransbank);
      }
    }
  }, []);

  return (
    <TravelPageContext.Provider
      value={{
        anonymous,
        drawerRender,
        hasReturn,
        isGone,
        integrationTokens,
        options,
        search,
        step,
        transaction,
        visible,
        quotas,
        passengersView,
        currentQuotaSelected,
        activeIsGoneTravel,
        percentDiscountValue,
        uuidTransbank,
        updateUuidTransbank,
        setPercentDiscountValue,
        setActiveIsGoneTravel,
        setCurrentQuotaSelected,
        setQuotas,
        setPassengersView,
        setAnonymous,
        setDrawerRender,
        setHasReturn,
        setIsGone,
        setIntegrationTokens,
        setOptions,
        setSearch,
        setStep,
        setTransaction,
        setVisible,
        setuuidTransbank,
      }}>
      {children}
    </TravelPageContext.Provider>
  )
}
